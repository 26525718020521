import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";
import SvgSeries from "../../core/components/SvgSeries";
import WhiteBox from "../../core/components/WhiteBox";
import { createSeries, seriesToMovingAverage } from "../../core/charts";

const QuantitySummary = props => {
  /**
   * A box summarising the key information for a quantity.
   */

  const {quantity, showSummary} = props;

  let series = createSeries(quantity.series, {});
  if (series.data.length > 5 && series.type !== "line" && series.type !== 3) {
    series = seriesToMovingAverage(series);
  }
  series = series.data;

  const valueClass = classNames({
    "quantity-value": true, "hidden": quantity.latestValue === null,
    "no-series": !showSummary
  })
  
  return (
    <WhiteBox className="quantity-summary" element={Link} to={`/quantities/${quantity.id}/`}>
      <div className="quantity-name">{quantity.name}</div>
      <div className={valueClass}>
        {quantity.latestValue === null ? "" : Number(quantity.latestValue).toLocaleString()}
        <span className="units">{quantity.latestValue === null ? "." : quantity.units}</span>
      </div>
      {showSummary && <SvgSeries series={series} lineWidth={1} step={quantity.seriesType === 2} dots={quantity.seriesType === 3}/>}
    </WhiteBox>
  )
}

QuantitySummary.propTypes = {
  quantity: PropTypes.object.isRequired,
  showSummary: PropTypes.bool
}

export default QuantitySummary;